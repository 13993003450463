import React from 'react';

const Categorias = ({ listaCategorias, handleCategoriaChange }) => {

  const renderCategoria = (categoria) => (
    <div key={categoria.codCategoria} className="flex items-center mb-2">
    <input
  type="checkbox"
  id={`categoria-${categoria.codCategoria}`}
  name={`categoria-${categoria.codCategoria}`}
  value={categoria.codCategoria}
  onChange={() => handleCategoriaChange(categoria.codCategoria)} // Esta línea debería funcionar ahora
  className="mr-2"
/>
      <label htmlFor={`categoria-${categoria.codCategoria}`} className="text-sm">
        {categoria.nomCategoria}
      </label>
    </div>
  );

  return (
    <div className="bg-white border rounded-lg shadow mb-5 w-full">
      <h1 className="border border-b-2 p-3 font-bold">Categorías del producto</h1>
      <div className="p-4">
        {listaCategorias.map(renderCategoria)} {/* Renderiza todas las categorías */}
      </div>
    </div>
  );
};

export default Categorias;
