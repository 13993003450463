import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { obtenerProductosPorSubcategoria } from "../../utils/apiProductos"
import { Card } from "../../components/CardSlider"
import SideBarProductos from "../../components/SideBarProductos"


export const ProductsBySubcategory = () => {
  const { codCategory, codSubcategory } = useParams();
  const [productos, setProductos] = useState([]);
  const [categoriasSeleccionadas, setCategoriasSeleccionadas] = useState([]);
  const [productosFiltrados, setProductosFiltrados] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await obtenerProductosPorSubcategoria(codCategory, codSubcategory);
        setProductos(data.productos);
        setProductosFiltrados(data.productos)

      } catch (error) {
        console.error("Error en la solicitud:", error);
      }
    };
    fetchData();
  }, [codCategory, codSubcategory]);



  // Filtrar productos basado en categoriasSeleccionadas
  const productosPorCategoria = categoriasSeleccionadas.length
    ? productos.filter(producto =>
        categoriasSeleccionadas.includes(producto.categoriaSecundaria.codCategoria)
      )
    : productos;



    const handleFilterChange = (selectedCategories) => {
      if (selectedCategories.length === 0) {
        setProductosFiltrados(productosPorCategoria);
        console.log("productosPorCategoria", productosPorCategoria)
         // Si no hay categorías seleccionadas, mostrar todos
      } else {
        const filtered = productos.filter(producto =>
          selectedCategories.includes(producto.categoriaSecundaria.codCategoria)
        );
        setProductosFiltrados(filtered);
        console.log(filtered)
      }
    };


  return (
    <div className="flex flex-col md:flex-row mt-16 mx-10 min-h-screen 2xl:px-20">
      <SideBarProductos 
        handleFilterChange={handleFilterChange} 
        productos={productosFiltrados}
      />
      <div className="flex flex-col md:pl-6 w-5/6">
        <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
          {productosPorCategoria.map((producto) => (
            <Card
              key={producto.id}
              imagen={producto.imagen}
              precioProducto={producto.precioProducto}
              precioRebajado={producto.precioRebajado}
              nomProducto={producto.nomProducto}
              codProducto={producto.codProducto}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
