import react, { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faShop, faTruck } from "@fortawesome/free-solid-svg-icons"

export const EstadoPedido = (codEstpedido) => {

  const estadoActual = codEstpedido.estado


  return (
    <div className="">
      <h3 className="pb-8">Estado de Pedido: </h3>
      {/* <p>{estadoActual}</p> */}
      <div className="flex justify-between items-center md:p-6">
        <div className="flex items-center">
          <div
            className={`${
              estadoActual === "7" ? "bg-secundario/75" : "bg-white"
            } border border-gray-700 rounded flex items-center justify-center w-12 h-12`}
          >
            <FontAwesomeIcon icon={faShop} />
          </div>
        </div>
        <div className="border border-gray-400 w-full"></div>

        <div className="flex items-center">
          <div
            className={`${
              estadoActual === "8" ? "bg-secundario/75" : "bg-white"
            } border border-gray-700 rounded flex items-center justify-center w-12 h-12`}
          >
            <FontAwesomeIcon icon={faTruck} />
          </div>
        </div>
        <div className="border border-gray-400 w-full"></div>

        <div className="flex items-center">
          <div className="bg-gray-400"></div>
          <div
            className={`${
              estadoActual === "9" ? "bg-secundario/75" : "bg-white"
            } border border-gray-700 rounded flex items-center justify-center w-12 h-12`}
          >
            <FontAwesomeIcon icon={faCheck} />
          </div>
        </div>
      </div>
    </div>
  )
}
