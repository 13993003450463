import React from "react"
import { Link } from "react-router-dom"

const CategoriaItem = ({ categoria, imagen }) => {

  return (
    <Link to={`/productos/category/${categoria.codCategoria}`}>
    <div className="flex flex-col items-center space-y-2">
      {/* Imagen redondeada */}
      <img
        src={imagen}
        alt={categoria.nomCategoria}
        className="w-32 h-32 object-cover rounded-full" // 100px = 24rem
      />
      {/* Nombre de la categoría */}
      <span className="text-center text-gray-700 font-semibold">
        {categoria.nomCategoria}
      </span>
    </div>
    </Link>
  )
}

export default CategoriaItem
