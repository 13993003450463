import React, { useState, useCallback, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { login } from "../../utils/apiRegistro"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEyeSlash, faEye } from "@fortawesome/free-regular-svg-icons"
import { useUserContext } from "../../contexts/UserContext"


const LoginForm = ({ onSubmit }) => {
  const [email, setEmail] = useState("")
  const [clave, setClave] = useState("")
  const [showClave, setShowClave] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(true)
  const [loginError, setLoginError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const navigate = useNavigate()

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    setIsValidEmail(emailRegex.test(email))
  }

  const handleEmailChange = (e) => {
    setEmail(e.target.value)
    setIsValidEmail(true)
  }

  const handlePasswordChange = (e) => {
    setClave(e.target.value)
    setLoginError(false)
    setErrorMessage("") // Clear the error message when password changes
  }

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault()
      validateEmail()

      if (isValidEmail) {
        setIsLoading(true)

        try {
          const response = await login({ email, clave })
          // Establecer la expiración en 24 horas (ajusta según tus necesidades)
          const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000
          localStorage.setItem("userData", JSON.stringify(response.usuario))
          localStorage.setItem("expirationTime", expirationTime)
       

          setTimeout(() => {
            navigate("/")
          }, 1500)
        } catch (error) {
          if (error.response) {
            const status = error.response.status
            if (status === 400) {
              setLoginError(true)
              setErrorMessage(
                "Usuario no encontrado con los datos proporcionados."
              )
            } else if (status === 402) {
              setErrorMessage(
                "La cuenta está en proceso de validación. Por favor, espere."
              )
            } else if (status === 405) {
              setErrorMessage(
                "La cuenta está bloqueada. Por favor, contacta con soporte."
              )
            } else {
              console.log("Error durante el inicio de sesión:", error)
            }
          } else {
            console.log("Error durante el inicio de sesión:", error)
          }
        }

        setIsLoading(false)
      } else {
        console.log("Correo electrónico no válido")
      }
    },
    [isValidEmail, email, clave, navigate]
  )

  const toggleShowClave = () => {
    setShowClave(!showClave)
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col">
        <label htmlFor="email" className="text-sm text-gray-500 mb-1 pl-2">
          Digite su email
        </label>
        <input
          type="email"
          className={`border focus:outline-secundario p-3 rounded-lg ${
            !isValidEmail && "border-red-500"
          }`}
          value={email}
          onChange={handleEmailChange}
        />
        {!isValidEmail && (
          <p className="text-red-500 text-sm mt-1">
            Por favor, ingrese un correo electrónico válido.
          </p>
        )}
      </div>
      <div className="flex flex-col mt-4 relative">
      <label htmlFor="password" className="text-sm text-gray-500 mb-1 pl-2">
        Digite su contraseña
      </label>
      <div className="relative w-full">
        <input
          type={showClave ? 'text' : 'password'}
          className={`border focus:outline-secundario p-3 rounded-lg w-full pr-10 ${
            loginError ? "border-red-500" : ""
          }`}
          value={clave}
          onChange={handlePasswordChange}
        />
        <button
          type="button"
          onClick={toggleShowClave}
          className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400"
        >
          <FontAwesomeIcon icon={showClave ? faEyeSlash : faEye} />
        </button>
      </div>
      {errorMessage && (
        <p className="text-red-500 text-xs mt-2">{errorMessage}</p>
      )}
    </div>
      <button
        type="submit"
        className={`${
          !isValidEmail || isLoading ? "bg-gray-300" : "bg-secundario"
        } p-3 w-full text-white mt-4 rounded-lg text-base font-medium`}
        disabled={!isValidEmail || isLoading}
      >
        {isLoading ? (
          <svg
            className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.86 3.182 7.978l2.02-2.687zm8.838 1.57A9.503 9.503 0 0017 12h-4a5.002 5.002 0 00-4.646-4.995L8 7.005A7.964 7.964 0 0112 4c4.418 0 8 3.582 8 8h-4a3.001 3.001 0 01-3.162 2.862l1.02-2.703z"
            ></path>
          </svg>
        ) : (
          "Continuar"
        )}
      </button>
    </form>
  )
}

const Login = ({ origin }) => {
  const navigate = useNavigate()
  const { isLoggedIn, login } = useUserContext();



  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn, navigate]);




  const handleLoginSubmit = async ({ email, clave }) => {
    try {
      const response = await login({ email, clave });

      // Establecer la expiración en 24 horas (ajusta según tus necesidades)
      const expirationTime = new Date().getTime() + 24 * 60 * 60 * 1000;

      localStorage.setItem("userData", JSON.stringify(response.usuario));
      localStorage.setItem("expirationTime", expirationTime);

      login(); // Actualizar estado de autenticación
      navigate("/"); // Redirigir después de iniciar sesión
    } catch (error) {
      console.log("Error durante el inicio de sesión:", error);
    }
  };


  return (
    <div className="flex flex-col items-center pt-20">
      <div className="bg-white rounded-lg shadow-lg p-14 max-w-md overflow-hidden">
        <div className="mb-4">
          <h1 className="text-2xl font-medium text-primario">
            ¡Bienvenido! ingresa tu e‑mail y contraseña
          </h1>
        </div>
        <LoginForm onSubmit={handleLoginSubmit} />
        <div className="text-center pt-4">
          <Link to="/olvido-contraseña">
            <span className="text-secundario font-medium">
              ¿Olvidó la Contraseña?
            </span>
          </Link>
        </div>
      </div>
      <ToastContainer position="top-center" style={{ width: "500px" }} />
    </div>
  )
}

export default Login
