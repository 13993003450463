import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Categorias from "./FiltroCategorias";
import FiltroGenerico from "./FiltroGenerico";
import { useFetchCategorias } from "../hooks/useFetchCategorias";
import { obtenerVendedores } from "../utils/apiVendedores";

const SideBarProductos = ({ handleFilterChange }) => {
  const { codCategory } = useParams();
  const [vendedoresSeleccionados, setVendedoresSeleccionados] = useState([]);
  const [vendedores, setVendedores] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([]);



  const navigate = useNavigate();
  useEffect(() => {
    const fetchVendedores = async () => {
      try {
        const data = await obtenerVendedores(); 
        setVendedores(data.datos);
      } catch (error) {
        console.error("Error al obtener vendedores:", error);
      }
    };

    fetchVendedores();
  }, []);



  const listaCategorias = useFetchCategorias();

  const categoriasHijas = listaCategorias
    ? listaCategorias.filter((categoria) => categoria.codPadre === codCategory)
    : [];



  

    const handleVendedoresChange = (vendedorSeleccionado) => {
      if (vendedorSeleccionado === vendedoresSeleccionados) {
        // Si se selecciona el mismo vendedor, deseleccionarlo
        setVendedoresSeleccionados([]);
      } else {
        // Seleccionar el nuevo vendedor y navegar a su página
        setVendedoresSeleccionados(vendedorSeleccionado);
        navigate(`/productos/seller/${vendedorSeleccionado}`);
      }
    };



    const handleCategoryChange = (codCategoria) => {
      setSelectedCategories((prevSelected) => {
        const newSelected = prevSelected.includes(codCategoria)
          ? prevSelected.filter(cat => cat !== codCategoria)
          : [...prevSelected, codCategoria];
  
        handleFilterChange(newSelected); // Llama a la función de filtrado
        return newSelected;
      });
    };




  return (
    <div className="md:w-1/6">
      <Categorias listaCategorias={categoriasHijas} handleCategoriaChange={handleCategoryChange} />
      <FiltroGenerico
        listaItems={vendedores}
        titulo="Vendedores"
        handleItemChange={handleVendedoresChange}
        items={vendedoresSeleccionados ? [vendedoresSeleccionados] : []} 
        idProp="codVendedor" 
        nombreProp="razonSocial"
      />
       {vendedoresSeleccionados.length > 0 && (
        <div className="">
          {vendedoresSeleccionados.map((vendedor) => (
            <div className="text-red-300" key={vendedor.codVendedor}>
              <span> {vendedor.razonSocial}</span>
               
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SideBarProductos;

