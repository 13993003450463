import React, { createContext, useContext, useState, useEffect } from "react";

const UserContext = createContext();

export function useUserContext() {
  return useContext(UserContext);
}

export function UserProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    if (userData) {
      setIsLoggedIn(true);
    }
    setIsLoading(false); // Indica que ha terminado de cargar
  }, []);

  const login = () => {
    setIsLoggedIn(true);
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("userData");
    localStorage.removeItem("expirationTime");
  };

  const userContextValue = {
    isLoggedIn,
    login,
    logout,
  };

  if (isLoading) {
    // Mostrar un indicador de carga o mensaje
    return <div>Cargando...</div>;
  }

  return (
    <UserContext.Provider value={userContextValue}>
      {children}
    </UserContext.Provider>
  );
}
