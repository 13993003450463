
import bannerVendedor6 from '../media/vendedores/bannerVendedor6.png'
import bannerVendedor7 from '../media/vendedores/bannerVendedor7.png'
export const imagenesVendedores = {
    "7": bannerVendedor7,
    "6": bannerVendedor6,
    // "8": bannerVendedor3,
    // Agrega más vendedores según los codVendedor
  };
  
  export const getImagenVendedor = (codVendedor) => {
    return imagenesVendedores[codVendedor] 
  };