import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { obtenerProductosPorVendedor } from "../utils/apiProductos";
import Categorias from "./FiltroCategorias";

export const SideBarBySeller = ({ handleFilterChange}) => {
  const { codVendedor } = useParams();
  const [catPrin, setCatPrin] = useState([]);
  const [catSec, setCatSec] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await obtenerProductosPorVendedor(codVendedor);
        const nuevasCatPrin = [];
        const nuevasCatSec = [];

        data.productos.forEach((prod) => {
          const { categoriaPrincipal, categoriaSecundaria } = prod;
          if (!nuevasCatPrin.some(cat => cat.codCategoria === categoriaPrincipal.codCategoria)) {
            nuevasCatPrin.push(categoriaPrincipal);
          }
          if (!nuevasCatSec.some(cat => cat.codCategoria === categoriaSecundaria.codCategoria)) {
            nuevasCatSec.push(categoriaSecundaria);
          }
        });

        setCatPrin(nuevasCatPrin);
        setCatSec(nuevasCatSec);
      } catch (error) {
        console.error("Error en la solicitud:", error);
      }
    };
    fetchData();
  }, [codVendedor]);

  const handleCategoryChange = (codCategoria) => {
    setSelectedCategories((prevSelected) => {
      const newSelected = prevSelected.includes(codCategoria)
        ? prevSelected.filter(cat => cat !== codCategoria)
        : [...prevSelected, codCategoria];

      handleFilterChange(newSelected); // Llama a la función de filtrado
      return newSelected;
    });
  };

  return (
    <div className="md:w-1/6">
      <Categorias
        listaCategorias={catPrin}
        handleCategoriaChange={handleCategoryChange}
      />
      <Categorias
        listaCategorias={catSec}
        handleCategoriaChange={handleCategoryChange}
      />
    </div>
  );
};
