import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { obtenerProductosPorVendedor } from "../../utils/apiProductos";
import { Card } from "../../components/CardSlider";
import { getImagenVendedor } from "../../data/imagenesVendedores";
import { SideBarBySeller } from "../../components/SideBarBySeller";


export const ProductsBySeller = () => {
  const { codVendedor } = useParams();
  const [productos, setProductos] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await obtenerProductosPorVendedor(codVendedor);
        setProductos(data.productos);
        setFilteredProducts(data.productos); // Inicialmente, todos los productos están filtrados
      } catch (error) {
        console.error("Error en la solicitud:", error);
      }
    };
    fetchData();
  }, [codVendedor]);

  const handleFilterChange = (selectedCategories) => {
    if (selectedCategories.length === 0) {
      setFilteredProducts(productos); // Si no hay categorías seleccionadas, mostrar todos
    } else {
      const filtered = productos.filter(producto =>
        selectedCategories.includes(producto.categoriaPrincipal.codCategoria) ||
        selectedCategories.includes(producto.categoriaSecundaria.codCategoria)
      );
      setFilteredProducts(filtered);
    }
  };

  const imagenVendedor = getImagenVendedor(codVendedor);

  return (
    <div>
      {imagenVendedor && (
        <img
          src={imagenVendedor}
          alt="Banner del vendedor"
          className="bg-cover w-full object-cover"
        />
      )}
      <div className="flex flex-col md:flex-row mt-16 mx-10 min-h-screen 2xl:px-20">
        <SideBarBySeller handleFilterChange={handleFilterChange} productos={productos} />
        <div className="flex flex-col md:pl-6 w-5/6">
          <div className="grid grid-cols-4 gap-4">
            {filteredProducts.map(producto => (
              <Card
                key={producto.id}
                imagen={producto.imagen}
                precioProducto={producto.precioProducto}
                precioRebajado={producto.precioRebajado}
                nomProducto={producto.nomProducto}
                codProducto={producto.codProducto}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
